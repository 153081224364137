import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
	loaderRef: PropTypes.func,
	text: PropTypes.string,
	isLoading: PropTypes.bool,
	isSaving: PropTypes.bool,
	small: PropTypes.bool,
	large: PropTypes.bool,
	white: PropTypes.bool,
	inline: PropTypes.bool,
	overlay: PropTypes.bool,
	left: PropTypes.bool,
	style: PropTypes.object,
	marginTop: PropTypes.number
};

export const Loader = ({ 
	loaderRef,
	text, 
	isLoading,
	isSaving, 
	small, 
	large,
	white, 
	inline, 
	overlay,
	left,
	style,
	marginTop
}) => {
	const renderLoader = () => (
		<div style={style} ref={loaderRef} className={classNames(
			'loader', 
			{ 'loader-sm': small }, 
			{ 'loader-white': white },
			{ 'loader-inline': inline },
			{ 'loader-large': large },
			{ 'pull-left': left }
		)}></div>
	);

	const renderText = () => {
		if (text)
			return <h4>{text}</h4>;

		if (isLoading)
			return <h4>Loading. Please wait...</h4>;

		if (isSaving)
			return <h4>Saving. Please wait...</h4>;
	};

	if (inline)
		return renderLoader();
	
	if (overlay)
		return <div className="overlay">{ renderLoader() }</div>;

	return (
		<div style={{overflow: 'hidden', marginTop }} className="text-center">
			{renderLoader()}
			{renderText()}        
		</div>
	);
};

Loader.propTypes = propTypes;