import React from 'react';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { DashboardLinkButton } from '../../common/uiElements';
import renderLinkButton from './renderLinkButton';
import { allowFeature } from '../../../app/helpers/permissionHelper';
import WorkerPermission from '../../../app/enums/WorkerPermission';

const DriversDashboard = () => (
	<Row>
		<Col xs={12}>
			<FormGroup>
				<DashboardLinkButton color="success" text="Do a Pre-Start" to="/compliance/preStart/new" icon="check-square-o" />
			</FormGroup>
		</Col>
		{ 
			renderLinkButton(
				'Report Incident', 
				'https://www.incidentreporting.com.au/IncidentReporting/Ir.aspx?p=Wmlx/ed67xPKrP9mLAe2yY23cCdzagTQCKpGjLzhEQSRjnTW', 
				'warning', 
				{ xs: 6 }, 
				'danger', 
				'2021-01-20',
				true
			) 
		}
		<Col xs={6}>
			<FormGroup>
				<DashboardLinkButton text="My Profile" to="/me" icon="user" color="success" />
			</FormGroup>
		</Col>
		<Col xs={6}>
			<FormGroup>
				<DashboardLinkButton text="Leave Requests" to="/me/leaveRequests" icon="plane" color="warning" />
			</FormGroup>
		</Col>
		<Col xs={6}>
			<FormGroup>
				<DashboardLinkButton color="primary" text="Current Run Mudmaps" to="/mudmaps" icon="map" releaseDate='2025-03-07' />
			</FormGroup>
		</Col>
		{
			allowFeature(WorkerPermission.ENABLE_FLEET_TRACKING) && (
				renderLinkButton('Fleet Tracking', '/tracking/fullMap', 'globe', { xs: 12 }, 'info')
			)
		}
	</Row>
);

export default DriversDashboard;