import React, { useEffect, useMemo, useState } from 'react';
import { ErrorBox, Loader } from '../../../common/uiElements';
import { PlacesList } from '../../../common/lists';
import { orderBy } from 'lodash';
import { RunLiveStatusListFilter, RunLiveStatusListItem } from '../../../../app/models/Run/RunLiveStatus';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';
import RunsLiveStatusPanelFilter from './subComponents/RunsLiveStatusPanelFilter';
import FontAwesome from 'react-fontawesome';
import RunApi from '../../../../app/api/RunApi';
import RosterTaskModal from '../../roster/tasks/TaskModal';
import RunsLiveStatusList from './subComponents/RunsLiveStatusList';
import './RunsLiveStatusPanel.scss';

interface IProps {
	isTvDashboard?: boolean;
}

const RunsLiveStatusPanelContainer: React.FC<IProps> = props => {
	const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>(undefined);
	const [runsList, setRunsList] = useState<RunLiveStatusListItem[]>([]);
	const [runsListFilter, setRunsListFilter] = useState(new RunLiveStatusListFilter())
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingInBackground, setIsLoadingInBackground] = useState(false);
	const [errorLoading, setErrorLoading] = useState<Error | undefined>(undefined);

	useEffect(() => {
		// Hide left menu
		if (!document.body.classList.contains('sidebar-collapse'))
			document.body.classList.add('sidebar-collapse');		
	}, [])

	const handleChangeWorkerName = (workerName: string) => {
		setRunsListFilter({ ...runsListFilter, workerName });
	}

	const handleChangeFleetNumber = (fleetNumber: string) => {
		setRunsListFilter({ ...runsListFilter, fleetNumber });
	}

	const handleChangeRunNumber = (runNumber: string) => {
		setRunsListFilter({ ...runsListFilter, runNumber });
	}

	const handleChangeStore = (store: string) => {
		setRunsListFilter({ ...runsListFilter, store });
	}

	const handleChangeRunType = (runTypes: string[]) => {
		setRunsListFilter({ ...runsListFilter, runTypes: [...runTypes] });
	}

	const handleChangeStatus = (status: string[]) => {
		setRunsListFilter({ ...runsListFilter, status: [...status] })
	};

	const handleChangeDepot = (depotId: string) => {
		setRunsListFilter({ ...runsListFilter, depotId });
	};
	
	const handleSelectTask = (selectedTaskId: number) => {
		setSelectedTaskId(selectedTaskId);
	};
	
	const handleCloseTaskDetailsModal = () => {
		setSelectedTaskId(undefined);
	};

	const fetchRuns = async (loadInBackground?: boolean) => {
		// setRunsList([...mockup.map((run: any) => new RunLiveStatusListItem(run))]);
		setIsLoadingInBackground(loadInBackground || false);
		setIsLoading(!loadInBackground);
	
		try {
			const runsList = await RunApi.getRunsLiveStatus();
			setRunsList([...runsList.map(run => new RunLiveStatusListItem(run))]);
		} catch (error) {
			setErrorLoading(error as any);
		} finally {
			setIsLoading(false);
			setIsLoadingInBackground(false);
		}
	}

	useEffect(() => {
		fetchRuns();
		const intervalTimer = setInterval(() => fetchRuns(true), 60 * 5 * 1000);
		return () => clearInterval(intervalTimer);
	}, []);

	// #region LIST ORDERING
	const runsListOrdered = useMemo(() => {
		const runsListFirstOrdering = orderBy(runsList, [
			// run => run.arrivalETA ?? Infinity
			'arrivalETA',
			'arrivalTime',
		]);

		return orderBy(runsListFirstOrdering, 
			[
				run => {
					if (run.Task.isStartTimeAdjustNeeded) return 6;
					if (run.isRunCompleted) return 5;
					if (run.arrivalTime && !run.actualFinishTime) return 0;
					if (run.isComingBack) return 1;
					if (run.isRunStarted) return 2;
					if (run.isRunStartLate) return 3;
					return 4;
				}
			]
	)
	}, [runsList]);
	// #endregion

	// #region LIST FILTERS
	const { runNumber, fleetNumber, workerName, store, depotId, status, runTypes } = runsListFilter;
	const filteredRunsList = useMemo(() => {
		if (!runsListOrdered)
			return [];

		return [
			...runsListOrdered.filter(run => (
				// Filter out external shuttles for now
				!run.runNumberCombined.includes('ExShuttle') &&

				// Depot
				(!depotId || run.Task.startingDepotId.toString() === depotId) 
	
				// Worker
				&& (
					!workerName 
					|| (
						run.Driver?.shortName.toLowerCase().includes(workerName.toLowerCase())
						|| run.Offsider?.shortName.toLowerCase().includes(workerName.toLowerCase())
						|| run.Trainee?.shortName.toLowerCase().includes(workerName.toLowerCase())
					)
				) 
	
				// Fleet Number
				&& (
					!fleetNumber 
					|| (
						run.MainVehicle?.fleetNumber.includes(fleetNumber)
						|| run.Trailer1?.fleetNumber.includes(fleetNumber)
						|| run.Trailer2?.fleetNumber.includes(fleetNumber)
					)
				)
	
				// Run Number
				&& (!runNumber || run.runNumberCombined.toLowerCase().includes(runNumber.toLowerCase()))
	
				// Store
				&& (
					!store || run.RunCustomerOrders.some(order => (
						order.Store.name.toLowerCase().includes(store.toLowerCase())
						|| order.Store.storeNumber?.includes(store)
					))
				)
	
				// Status
				&& (status.length === 0
					|| (status.includes('LATE_START') && run.isRunStartLate)
					|| (status.includes('NOT_STARTED') && !run.isRunStarted)
					|| (status.includes('IN_PROGRESS') && run.isRunStarted && !run.isRunCompleted)
					|| (status.includes('COMING_BACK') && (run.isComingBack || (run.arrivalTime && !run.actualFinishTime)))
					|| (status.includes('COMPLETED') && run.isRunCompleted)
				)
				
				// Run Types
				&& (
					runTypes.length === 0
					|| (runTypes.includes('WW') && run.runNumberCombined.includes('WW'))
					|| (runTypes.includes('FS') && run.runNumberCombined.includes('FS'))
					|| (runTypes.includes('SHUTTLE') && run.runNumberCombined.includes('Shuttle'))
				)
			))
		];
	}, [runsListOrdered, depotId, workerName, fleetNumber, runNumber, store, status, runTypes]);
	// #endregion

	const totalRunsNotStarted = useMemo(() => filteredRunsList.filter(run => !run.isRunStarted).length, [filteredRunsList]);
	const totalRunsLateStart = useMemo(() => filteredRunsList.filter(run => run.isRunStartLate).length, [filteredRunsList]);
	const totalRunsInProgress = useMemo(() => filteredRunsList.filter(run => run.isRunStarted && !run.isRunCompleted).length, [filteredRunsList]);
	const totalRunsComingBack = useMemo(() => filteredRunsList.filter(run => run.isComingBack).length, [filteredRunsList]);
	const totalRunsOverBudget = useMemo(() => filteredRunsList.filter(run => run.isOverBudget).length, [filteredRunsList]);
	const totalRunsCompleted = useMemo(() => filteredRunsList.filter(run => run.isRunCompleted).length, [filteredRunsList]);
	
	const renderSummaryItem = (title: string, color: string, icon: string, value: string | number) => (
		<Col className="summary-box" sm={6} md={4} lg={2}>
			<div className="info-box">
				<span className={`info-box-icon bg-${color}`}>
					<FontAwesome name={icon} />
				</span>
				<div className="info-box-content" style={{ padding: '0 10px' }}>
					<b style={{ fontSize: 42 }}>{ isLoading ? '-' : value }</b><br/>
					<span style={{ fontSize: 20, fontWeight: 'bold' }}>{ title }</span>
				</div>
			</div>
		</Col>
	)

	return (
		<>
			<section className="content panel-live-monitoring">
				<div className="title">
					<span>Runs Live Status Dashboard</span>
					{
						props.isTvDashboard && (
							<PlacesList 
								id="depotId"
								type="palletJackLiveMonitoring" 
								firstOptionText="--- ALL DEPOTS ---"
								onChange={handleChangeDepot} 
							/>
						)
					}
				</div>
				<Row>
					{ renderSummaryItem('NOT STARTED', 'gray', 'clock-o', totalRunsNotStarted) }
					{ renderSummaryItem('IN PROGRESS', 'blue', 'refresh', totalRunsInProgress) }
					{ renderSummaryItem('COMING BACK', 'green', 'undo', totalRunsComingBack) }
					{ renderSummaryItem('COMPLETED', 'green', 'check', totalRunsCompleted) }
					{ renderSummaryItem('LATE START', 'red', 'warning', totalRunsLateStart) }
					{ renderSummaryItem('OVER BUDGET', 'orange', 'dollar', totalRunsOverBudget) }
				</Row>
				{
					!props.isTvDashboard && (
						<FormGroup>
							<RunsLiveStatusPanelFilter 
							{ ...runsListFilter }
							onChangeWorker={handleChangeWorkerName}
							onChangeFleetNumber={handleChangeFleetNumber}
							onChangeRunNumber={handleChangeRunNumber}
							onChangeStore={handleChangeStore}
							onChangeDepot={handleChangeDepot}
							onChangeRunType={handleChangeRunType}
							onChangeStatus={handleChangeStatus}
						/>
						<Row>
							<Col xs={6}>
								<Button 
									block bsStyle="info" 
									disabled={isLoading || isLoadingInBackground}
									onClick={() => (!isLoading || !isLoadingInBackground) && fetchRuns()}
								>
									{
										isLoadingInBackground || isLoading ? (
											<>
												<Loader inline white/> Loading...
											</>
										) : (
											<>
												<FontAwesome name="refresh" /> Refresh List
											</>
										)
									}
								</Button>
							</Col>
							<Col xs={6}>
								<Button block bsStyle="warning" onClick={() => setRunsListFilter(new RunLiveStatusListFilter())}>
									<FontAwesome name="undo" /> Reset Filters
								</Button>
							</Col>
						</Row>
						</FormGroup>
					)
				}
				{
					errorLoading ? (
						<ErrorBox error={errorLoading} retryFunc={fetchRuns} />
					) : (
						<>
							{/* <ul className="list-inline">
								<li><Badge style={{ fontSize: 18 }} className="muted">NOT STARTED</Badge></li>
								<li><Badge style={{ fontSize: 18 }} className="danger">LATE START</Badge></li>
								<li><Badge style={{ fontSize: 18 }} className="primary">IN PROGRESS</Badge></li>
								<li><Badge style={{ fontSize: 18 }} className="success">COMING BACK</Badge></li>
								<li><Badge style={{ fontSize: 18 }} className="warning">OVER BUDGET</Badge></li>
							</ul> */}
							<RunsLiveStatusList 
								runsList={filteredRunsList}
								isLoading={isLoading}
								onSelect={handleSelectTask}
							/>
						</>
					)
				}
			</section>
			{
				selectedTaskId && (
					<RosterTaskModal 
						show
						isReadOnly
						taskId={selectedTaskId}
						onClose={handleCloseTaskDetailsModal}
						tasksList={[]}
						onSaveComplete={() => {}}
					/>
				)
			}
		</>
	);
}

export default RunsLiveStatusPanelContainer;
