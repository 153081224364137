/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import moment from 'moment';
import Icon from 'react-fontawesome';
import 'react-joyride/lib/react-joyride-compiled.css';
import './style.css';

// API
import PreStartApi from '../../../../../app/api/PreStartApi';

// UI Elements
import { AlertModal, ContentBox, Callout, ErrorBox, Loader } from '../../../../common/uiElements';
import { Row, Col, Button } from 'react-bootstrap';

// Step Components
import GeneralInformation from './steps/GeneralInformation';
import FaultReportsFirstList from './steps/FaultReportsFirstList';
import SecurityQuestions from './steps/SecurityQuestions';
import VehicleMechanicalCheck from './steps/VehicleMechanicalCheck';
import VehicleItemsCheck from './steps/VehicleItemsCheck';
import ProblemsChecking from './steps/ProblemsChecking';
import TermsAndAgreement from './steps/TermsAndAgreement';
import MassManagement from './steps/MassManagement';
import PreStartWaitingApproval from '../subComponents/PreStartWaitingApproval';

import FaultReportModalAddNew from '../../faultReport/subComponents/FaultReportModalAddNew';
import { VehicleTypes } from '../../../../../app/models/Vehicle';
import PreStartOverloadModal from '../subComponents/PreStartOverloadModal';
import PreStartTasksCheck from '../tasksCheck/PreStartTasksCheck';

const propTypes = {
	lastPreStartData: PropTypes.object,
	tasks: PropTypes.arrayOf(PropTypes.object),
	isFirstTime: PropTypes.bool,
	isPreStartV2: PropTypes.bool,
};

class PreStartFirstCheck extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			currentStep: 0,
			completedSteps: [],
			isGuideMode: false,
			isFirstTime: false,
			guideSteps: [],
			isSaving: false,
			isSaved: false,
			weightCheck: null,
			isCheckingWeight: false,
			isWaitingSupervisor: false,
			error: null,
			errorCheckingWeight: null,
			data: {
				// to avoid the error that was keeping the data when changing pages
				GeneralInformation: {},
				MassManagement: {}, 
				VehicleMechanicalCheck: {}, 
				SecurityQuestions: {}, 
				VehicleItemsCheck: {}, 
				TermsAndAgreement: {},
				FaultReports: []
				// fixing all objects on pre-start to not keep that while changing pages
			},
			preStartId: '',
			showFaultReportModal: false,
			showLastPreStartAlert: false,
			componentKey: Date.now(),

			// used to record what time the user started the pre-start process
			processStartedOn: undefined,
			
			showTaskSelection: props.isPreStartV2 || props.tasks?.length > 0,
			selectedTask: undefined,
		};

		this.totalSteps = 0;
	}

	addGuideSteps = guideSteps => {
		let newGuideSteps = guideSteps;

		if (!Array.isArray(newGuideSteps))
			newGuideSteps = [guideSteps];

		if (!newGuideSteps.length)
			return;

		this.setState({ 
			guideSteps: newGuideSteps 
		}, () => this.joyride && this.joyride.reset(true));
	}

	guideCallBack = (data) => {
		if(data.action === 'close') {
			sessionStorage.setItem('hidePreStartGuideTour', true);
			window.location.reload();
		}

		if (data.action !== 'back' && data.type === 'step:after' && data.step) {
			if (data.step.isLastStep || data.action === 'skip') 
				this.setState({ showFaultReportModal: false }, () => {
					if (this.state.currentStep < this.totalSteps - 1)
						this.onNextStep(true);
					else
						this.setState({ isWaitingSupervisor: true });
				});
			else if (data.step.showFaultReportModal)
				this.setState({ showFaultReportModal: true });
			else if (data.step.goToWaitingApproval)
				this.setState({ isWaitingSupervisor: true });
		}
	}

	UNSAFE_componentWillMount() {
			const { lastPreStartData } = this.props;
			let showLastPreStartAlert = false;

			// If the last pre-start was not approved and finished in the last 15 minutes
			if (lastPreStartData && !lastPreStartData.checkOutIsApproved && moment().diff(lastPreStartData.checkOutOnLocal, 'minutes') < 15)
				showLastPreStartAlert = true;

			this.setState({ isGuideMode: false, isFirstTime: false, showLastPreStartAlert });
	}

	componentDidUpdate() {
		if (!this.props.router)
			return;

		if (!window.$.isEmptyObject(this.state.data?.GeneralInformation) && !this.state.isGuideMode && !this.state.isSaved && !this.state.isWaitingSupervisor) {
			window.onbeforeunload = () => true;
			this.props.router.setRouteLeaveHook(this.props.route, () => {
				if(window.confirm('Are you sure that you want to leave this page? The pre start data won’t be saved.'))
					return true;
				else     
					return false;
			});
		} else {
			window.onbeforeunload = null;
			this.props.router.setRouteLeaveHook(this.props.route, () => true);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
		window.onbeforeunload = null;
	}

	handleChangeData = (newData, id) => {
		return this.setState({
			// Set the process started on the first time the user changes any data
			processStartedOn: this.state.processStartedOn || moment().format('YYYY-MM-DD HH:mm:ss'),
			data: {
				...this.state.data,
				[id]: newData
			}
		});
	}

	isCurrentStep = (step) => { 
		return this.state.currentStep == step; 
	}

	isStepCompleted = (step) => { 
		return this.state.completedSteps.indexOf(step) !== -1; 
	}

	isActiveStep = (step) => { 
		return this.state.activeStep == step;
	}

	isValid = () => {
		window.$('.wizard-steps li.selected').removeClass('invalid');
		
		var form = window.$('.wizard-content.active').find('form')[0];	
		
		if (!form || typeof form == 'undefined')
			return true;

		var valid = window.$(form).valid();

		if (!valid) {
			window.$('.wizard-steps li.active').addClass('invalid');
		}

		return valid;
	}

	checkWeight = (isNotTypingError = false) => {
		const { MassManagement, GeneralInformation } = this.state.data;
			
		if (!MassManagement || !GeneralInformation)
			return this.onNextStep(true);

		this.setState({ isCheckingWeight: true, weightCheck: null, errorCheckingWeight: null });
		const { 
			mainVehFleetNumber, trailer1VehFleetNumber,
			trailer2VehFleetNumber, palletJackVehFleetNumber,
			palletJackIsIn, trolleyIsIn, depotId
		} = GeneralInformation;

		MassManagement.fleet1 = trailer1VehFleetNumber || mainVehFleetNumber;
		MassManagement.fleet2 = trailer2VehFleetNumber;
		
		const massManagementData = {
			...MassManagement,
			isNotTypingError,
			depotId,
			palletJackVehFleetNumber,
			trolleyIsIn,
			palletJackIsIn,
		};

		PreStartApi.checkWeight(massManagementData)
			.then(weightCheck => {
				if (this.unmounted) return;
				
				let PreStartNonConformanceIds = this.state.PreStartNonConformanceIds || [];
				if (weightCheck) {
					const { isNotOverloaded, preStartNonConformanceIds } = weightCheck;

					if (isNotOverloaded)
						this.onNextStep(true);
					else if (preStartNonConformanceIds)
						PreStartNonConformanceIds = PreStartNonConformanceIds.concat(preStartNonConformanceIds);

				}
				
				this.setState({ 
					PreStartNonConformanceIds, 
					weightCheck, 
					isCheckingWeight: false
				});
				
			})
			.catch(error => {
				console.error(error);
				this.setState({ isCheckingWeight: false, errorCheckingWeight: error });
			});
	}

	onNextStep = doNotValidate => {
		if(doNotValidate || this.isValid()) {
			var { completedSteps, currentStep, activeStep, data } = this.state;

			// If it is the Mass Management step, it needs to check the mass before move on
			if (!doNotValidate && activeStep === 1)
				return this.checkWeight();

			if (!this.isStepCompleted(activeStep))
				completedSteps.push(activeStep);

			const { GeneralInformation: { mainVehTypeID, trailer1VehFleetNumber } } = data;
			// If the user selected just a Prime Mover it should skip the Mass Management step
			if (activeStep === 0 && mainVehTypeID == VehicleTypes.PRIME_MOVER && !trailer1VehFleetNumber) {
				completedSteps.push(1);
				activeStep += 2;
				currentStep += 2;
			} else {
				activeStep += 1;
				currentStep += 1;
			}

			if (currentStep == activeStep)
				this.setState({ currentStep });

			this.setState({
				activeStep,
				completedSteps
			});

			return this.onStepChanged();
		}
	}

	onPreviousStep = () => { 
		let { activeStep, data } = this.state;
		const { GeneralInformation: { mainVehTypeID, trailer1VehFleetNumber } } = data;

		// If the user selected just a Prime Mover it should skip the Mass Management step
		if (activeStep === 2 && mainVehTypeID === VehicleTypes.PRIME_MOVER && !trailer1VehFleetNumber)
			activeStep -= 2;
		else
			activeStep -= 1;

		return this.setState({ activeStep }, this.onStepChanged); 
	}

	onStepChanged = () => {
		window.scrollTo(document.body, 0, 100);
		var form = window.$('.wizard-content.active').find('form')[0];
		
		if (!form || !window.$(form).validate)
			return;

		return window.$(form).validate().resetForm();
	}

	handleStartPreStartFromTask = selectedTask => {
		if (!selectedTask)
			return this.setState({ showTaskSelection: false })

		this.setState({
			showTaskSelection: false,
			selectedTask,
			data: {
				...this.state.data,
				GeneralInformation: {
					depotId: selectedTask.startingDepotId,
					dtoDepotName: selectedTask.startingDepotName,
					mainVehFleetNumber: selectedTask.mainVehFleetNumber,
					trailer1FleetNumber: selectedTask.trailer1FleetNumber,
					trailer2FleetNumber: selectedTask.trailer2FleetNumber,
				},
				MassManagement: {
					...this.state.data.MassManagement,
					PreStartRuns: selectedTask.RunCustomers.map(run => ({ ...run, number: run.runNumber, taskId: selectedTask.id }))
				}
			}
		})
	}

	onSavePreStart = () => {
		if(this.isValid()) {
			this.setState({ isSaving: true, error: false });

			let attachedFiles = [];
			let data = window.$.extend(true, {}, this.state.data);
			let { 
				GeneralInformation, 
				MassManagement,
				VehicleMechanicalCheck, 
				SecurityQuestions, 
				VehicleItemsCheck, 
				TermsAndAgreement,
				FaultReports
			} = data;
			const { PreStartNonConformanceIds } = this.state;

			// Signature File
			TermsAndAgreement.dtoSignatureFileIndex = attachedFiles.length;
			attachedFiles.push(TermsAndAgreement.signatureFile);

			// Trailer 1 Jaw Photo
			// GeneralInformation.dtoTrailer1JawsPhotoIndex = attachedFiles.length;
			// attachedFiles.push(GeneralInformation.trailer1JawsPhoto);

			// Trailer 2 Jaw Photo
			// GeneralInformation.dtoTrailer2JawsPhotoIndex = attachedFiles.length;
			// attachedFiles.push(GeneralInformation.trailer2JawsPhoto);
			
			// Mass Management
			if (!MassManagement || !MassManagement.PreStartRuns || (MassManagement.PreStartRuns[0] && !MassManagement.PreStartRuns[0].number))
				MassManagement = { PreStartRuns: [] };

			// Fault Report Photos
			if (FaultReports) {
				// Filter just the ones that don't have an ID, so they are new ones
				FaultReports = FaultReports.filter(f => !f.id);
				for (let i = 0; i < FaultReports.length; i++) {
					let FaultReportPhotos = FaultReports[i].FaultReportPhotos;
					for (let j = 0; j < FaultReportPhotos.length; j++) {
						let faultPhotoObject = FaultReportPhotos[j];
						if (faultPhotoObject.file) {
							let { file } = faultPhotoObject;
							faultPhotoObject.dtoFileIndex = attachedFiles.length;
							attachedFiles.push(file);
							delete faultPhotoObject.dataURL;
						}
					}
				}
			}

			// Vehicle Damage Photos
			let VehicleDamages = [];
			let damagePhotosObject = data.VehicleDamages;
			if (damagePhotosObject) {
				for (var fleetNumber in damagePhotosObject) {
					var damagePhotos = damagePhotosObject[fleetNumber];
					for (var i = 0; i < damagePhotos.length; i++) {
						var { file } = damagePhotos[i];
						VehicleDamages.push({
							fleetNumber,
							dtoFileIndex: attachedFiles.length
						});
						attachedFiles.push(file);
						delete damagePhotos[i].dataURL;
					}
				}
			}

			const { palletJackVehFleetNumber } = GeneralInformation;

			let dataToSend = {
				PreStartCheck: {
					processStartedOn: this.state.processStartedOn,
					...GeneralInformation,
					// Workaround to force drivers to select a pallet jack or select "None" if there is none
					palletJackVehFleetNumber: palletJackVehFleetNumber === '0' ? '' : palletJackVehFleetNumber,
					...MassManagement,
					...VehicleMechanicalCheck,
					...SecurityQuestions,
					...VehicleItemsCheck,
					...TermsAndAgreement,
					FaultReports
				},
				VehicleDamages,
				PreStartNonConformanceIds,	
				attachedFiles
			};

			PreStartApi.saveNewPreStart(dataToSend)
				.then((data) => {
					toastr.success('The data was successfully saved', 'Success!');
					if (this.unmounted) return;

					this.setState({ 
						preStartId: data.dbKeyReference, 
						processStartedOn: undefined,
						isSaving: false, 
						isSaved: true,
						isWaitingSupervisor: true
					});
				})
				.catch((error) => {
					console.error(error);
					this.setState({ isSaving: false, error });
				});
		}
	}

	renderStepClass = (step) => {
		var cssClass = '';

		if (this.isActiveStep(step))
			cssClass += 'active ';

		if (this.isCurrentStep(step))
			cssClass += 'current';
		else if (this.isStepCompleted(step))
			cssClass += 'completed';
		
		return cssClass;
	}

	renderSteps = () => {
		var { state, props } = this;

		let fleetNumbers = [];
		let mainVehFleetNumber, trailer1VehFleetNumber, trailer2VehFleetNumber, palletJackVehFleetNumber, dollyVehFleetNumber;
		if (state.data && state.data.GeneralInformation) {
			mainVehFleetNumber = state.data.GeneralInformation.mainVehFleetNumber;
			trailer1VehFleetNumber = state.data.GeneralInformation.trailer1VehFleetNumber;
			trailer2VehFleetNumber  = state.data.GeneralInformation.trailer2VehFleetNumber;
			palletJackVehFleetNumber = state.data.GeneralInformation.palletJackVehFleetNumber;
			dollyVehFleetNumber = state.data.GeneralInformation.dollyVehFleetNumber;

			if (mainVehFleetNumber) {
				fleetNumbers.push(mainVehFleetNumber);
			}
			if (trailer1VehFleetNumber) {
				fleetNumbers.push(trailer1VehFleetNumber);
			}
			if (trailer2VehFleetNumber) {
				fleetNumbers.push(trailer2VehFleetNumber);
			}
			if (palletJackVehFleetNumber) {
				fleetNumbers.push(palletJackVehFleetNumber);
			}
			if (dollyVehFleetNumber) {
				fleetNumbers.push(dollyVehFleetNumber);
			}
		}
		
		const steps = [
			<GeneralInformation 
				key="generalInformation"
				isPreStartV2={props.isPreStartV2 || state.selectedTask !== undefined}
				isGuideMode={state.isGuideMode} 
				readOnly={state.currentStep > 0} 
				data={state.data.GeneralInformation}
				isMainVehicleNotTracked={state.selectedTask?.isMainVehicleNotTracked}
				isTrailer1NotTracked={state.selectedTask?.isTrailer1NotTracked}
				subContractorAbn={state.selectedTask?.subContractorAbn}
				onChange={this.handleChangeData}
				addGuideSteps={this.addGuideSteps}
			/>,
			<MassManagement 
				key="massManagement"
				isPreStartV2={props.isPreStartV2 || state.selectedTask !== undefined}
				isGuideMode={state.isGuideMode} 
				data={state.data.MassManagement} 
				mainVehFleetNumber={mainVehFleetNumber}
				trailer1VehFleetNumber={trailer1VehFleetNumber}
				trailer2VehFleetNumber={trailer2VehFleetNumber}
				palletJackVehFleetNumber={palletJackVehFleetNumber || ''}
				dollyVehFleetNumber={dollyVehFleetNumber}
				processStartedOn={state.processStartedOn}
				onChange={this.handleChangeData}
				addGuideSteps={this.addGuideSteps}
			/>,
			<FaultReportsFirstList
				key="faultReportList"
				fleetNumbers={fleetNumbers}
				faultReportItems={state.data.FaultReports}
				onChange={this.handleChangeData} 
				isGuideMode={state.isGuideMode}
				addGuideSteps={this.addGuideSteps}
			/>,
			<SecurityQuestions 
				key="securityQuestions"
				data={state.data.SecurityQuestions} 
				onChange={this.handleChangeData} 
				addGuideSteps={this.addGuideSteps}
			/>,
			<VehicleMechanicalCheck 
				key="vehicleMechanicalCheck"
				data={state.data.VehicleMechanicalCheck} 
				faultReportItems={state.data.FaultReports}
				fleetNumbers={fleetNumbers}
				mainVehTypeID={state.data.GeneralInformation && state.data.GeneralInformation.mainVehTypeID}
				isThereTrailer={state.data.GeneralInformation && state.data.GeneralInformation.trailer1VehFleetNumber ? true : false}
				isThereDolly={state.data.GeneralInformation && state.data.GeneralInformation.dollyVehFleetNumber ? true : false}
				onChange={this.handleChangeData}
				onSaveFaultReport={this.handleChangeData}
				addGuideSteps={this.addGuideSteps}
			/>,
			<VehicleItemsCheck 
				key="vehicleItemsCheck"
				data={state.data.VehicleItemsCheck} 
				onChange={this.handleChangeData} 
				addGuideSteps={this.addGuideSteps}
			/>,
			<ProblemsChecking 
				key="problemsChecking"
				fleetNumbers={fleetNumbers}
				faultReportItems={state.data.FaultReports}
				vehicleDamageItems={state.data.VehicleDamages}
				onChange={this.handleChangeData}
				isGuideMode={state.isGuideMode}
				addGuideSteps={this.addGuideSteps}
			/>,
			<TermsAndAgreement 
				key="termsAndAgreements"
				fleetNumbers={fleetNumbers}
				data={state.data.TermsAndAgreement} 
				onChange={this.handleChangeData} 
				onCommenceTrip={this.onSavePreStart} 
				addGuideSteps={this.addGuideSteps}
			/>
		];

		this.totalSteps = steps.length;
		return steps;
	}

	renderContent = () => {
		var s = this.state;

		if (s.isSaving)
			return (
				<ContentBox color="primary">
					<Loader />
					<h4 className="text-center">Saving the data. Please wait...</h4>
				</ContentBox>
			);

		if (s.isCheckingWeight)
			return (
				<ContentBox color="primary">
					<Loader />
					<h4 className="text-center">Checking the weight. Please wait...</h4>
				</ContentBox>
			);

		if (s.isSaved || s.isWaitingSupervisor)
			return <PreStartWaitingApproval preStartId={s.preStartId} isFirstTime={s.isFirstTime} isGuideMode={s.isGuideMode} />;
		
		const steps = this.renderSteps();
		return (
			<div>
				{
					s.showLastPreStartAlert && (
						<AlertModal title="Warning" onClose={() => this.setState({ showLastPreStartAlert: false })}>
							<div className="text-center">Your last Pre-Start was not approved. Make sure that you are doing a brand new Pre-Start</div>
						</AlertModal>
					)
				}
				{
					(s.error || s.errorCheckingWeight) && (
						s.errorCheckingWeight ? (
							<ErrorBox error={s.errorCheckingWeight} retryFunc={this.checkWeight} />
						) : (
							<ErrorBox error={s.error} retryFunc={this.onSavePreStart}/>
						)
					)
				}
				{
					s.weightCheck && !s.weightCheck.isNotOverloaded && (
						<PreStartOverloadModal
							massManagementData={s.data.MassManagement}
							canBeTypingError={s.weightCheck.canBeTypingError}
							overloadedList={s.weightCheck.overloaded}
							onClose={() => this.setState({ weightCheck: null })} 
							onClickIsNotTypingError={() => this.checkWeight(true)}
						/>
					)
				}
				<div className="wizard-inline">
					<Row>
						<Col xs={12}>
							<ul className="wizard-steps">
								{
									steps.map((step, index) => (
										<li key={index} className={this.renderStepClass(index)} title={'Step ' + (index + 1)}>
											<a>{ index + 1 }</a>
										</li>
									))
								}
							</ul>
						</Col>
					</Row>
					{
						steps.map((step, index) => {
							if (index > s.currentStep)
								return null;

							return (
								<div key={index} className={('wizard-content ' + this.renderStepClass(index))}>
									<ContentBox color="primary" windowMaxHeight={true}>
										<Col xs={12}>
											{ step }
										</Col>
									</ContentBox>
									<ul className="wizard-buttons">
										{ 
											index > 0 && (
												<li>
													<Button bsStyle="warning" onClick={this.onPreviousStep}>
														<Icon name="arrow left" /> Previous
													</Button>
												</li>
											) 
										}
										{ 
											index < steps.length - 1 && (
												<li>
													<Button bsStyle="success" id="nextButton" onClick={() => this.onNextStep(false)}>
														Next <Icon name="arrow-right" />
													</Button>
												</li>
											) 
										}
									</ul>
								</div>
							);
						})
					}
				</div>
				{ s.showFaultReportModal && <FaultReportModalAddNew show={true} addGuideSteps={this.addGuideSteps} /> }
			</div>
		);
	}

	render() {
		const { state, props } = this;

		if ((props.isPreStartV2 || props.tasks?.length > 0) && state.showTaskSelection) 
			return (
				<PreStartTasksCheck 
					tasks={props.tasks} 
					isPreStartV2={props.isPreStartV2}
					onStartPreStart={this.handleStartPreStartFromTask} 
				/>
			)	
		
		return (
			<div key={state.componentKey}>
				<Callout color="success"><b>FIRST PART</b></Callout>
				{ this.renderContent() }
				
			</div>
		);
	}
}

PreStartFirstCheck.propTypes = propTypes;

export default PreStartFirstCheck;