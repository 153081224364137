/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Button, FormControl, FormGroup } from 'react-bootstrap';
import omit from 'lodash.omit';
import Icon from 'react-fontawesome';

import PreStartApi from '../../../../../app/api/PreStartApi';
import { Signature } from '../../../../common/inputs';
import { AlertModal, Callout, ErrorBox, ContentBox, DataStatusMessage, Form } from '../../../../common/uiElements';

// Steps
import FaultReports from './steps/FaultReports';
import VehicleDamages from './steps/VehicleDamages';
import DepotReturnInformation from './steps/DepotReturnInformation';


const propTypes = {
	/** The pre-start data */
	data: PropTypes.object.isRequired
};

class PreStartLastCheck extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			completedSteps: [],
			currentStep: 0,
			isSaving: false,
			isSaved: false,
			error: false,
			data: this.props.data,
			showLastPreStartAlert: false,

			// used to record what time the user started the pre-start process
			processStartedOn: undefined
		};

		this.handleChange = (newData) => {
			this.setState({
				// Set the process started on the first time the user changes any data
				processStartedOn: this.state.processStartedOn || moment().format('YYYY-MM-DD HH:mm:ss'),
				data: {
					...this.state.data,
					...newData
				}
			});
		};
	}

	componentDidUpdate() {
		if (!this.props.router)
			return;

		if (!this.state.isSaved) {
			window.onbeforeunload = () => true;
			this.props.router.setRouteLeaveHook(this.props.route, () => {
				if(window.confirm('Are you sure that you want to leave this page? The pre start data won’t be saved.'))
					return true;
				else     
					return false;
			});
		} else {
			window.onbeforeunload = null;
			this.props.router.setRouteLeaveHook(this.props.route, () => true);
		}
	}

	componentWillUnmount() {
		window.onbeforeunload = null;
		window.clearInterval(this.nextStepCounter);
	}

	UNSAFE_componentWillMount() {
		const { data } = this.props;

		// If the pre-start was started but not finished in the last 15 hours
		this.setState({ 
			showLastPreStartAlert: moment().diff(data.inspectedOnLocal, 'hours') >= 15 
		});
	}    

	/**
     * WIZARD METHODS
     */
	isCurrentStep = (step) => { 
		return this.state.currentStep == step; 
	}

	isStepCompleted = (step) => { 
		return this.state.completedSteps.indexOf(step) !== -1; 
	}

	isActiveStep = (step) => { 
		return this.state.activeStep == step; 
	}

	isValid = () => {
		window.$('.wizard-steps li.selected').removeClass('invalid');
		
		var form = window.$('.wizard-content.active').find('form')[0];	
		
		if (!form || typeof form == 'undefined')
			return true;

		var valid = window.$(form).valid();

		if (!valid) {
			window.$('.wizard-steps li.active').addClass('invalid');
		}

		return valid;
	}

	onNextStep = () => {
		if(this.isValid()) {
			var { completedSteps, currentStep, activeStep } = this.state;

			if (!this.isStepCompleted(activeStep))
				completedSteps.push(activeStep);

			if (currentStep == activeStep)
				this.setState({ currentStep: currentStep + 1 });

			this.setState({
				activeStep: activeStep + 1,
				completedSteps
			});

			return this.onStepChanged();
		}
	}

	onPreviousStep = () => { 
		let { activeStep } = this.state;
		return this.setState({ activeStep: activeStep - 1 }, this.onStepChanged()); 
	}

	onStepChanged = () => {
		window.scrollTo(document.body, 0, 100);
		var form = window.$('.wizard-content.active').find('form')[0];
		
		if (!form || !window.$(form).validate)
			return;

		return window.$(form).validate().resetForm();
	}

	renderStepClass = (step) => {
		var cssClass = '';

		if (this.isActiveStep(step))
			cssClass += 'active ';

		if (this.isCurrentStep(step))
			cssClass += 'current';
		else if (this.isStepCompleted(step))
			cssClass += 'completed';
	
		return cssClass;
	}
	/**
	 * END WIZARD METHODS
	 */

	getFleetNumbers = () => {
		let { data } = this.state;
		let fleetNumbers = [];

		let { mainVehFleetNumber, trailer1VehFleetNumber, trailer2VehFleetNumber, dollyVehFleetNumber, palletJackVehFleetNumber } = data;
		mainVehFleetNumber && fleetNumbers.push(mainVehFleetNumber);
		trailer1VehFleetNumber && fleetNumbers.push(trailer1VehFleetNumber);
		trailer2VehFleetNumber && fleetNumbers.push(trailer2VehFleetNumber);
		dollyVehFleetNumber && fleetNumbers.push(dollyVehFleetNumber);
		palletJackVehFleetNumber && fleetNumbers.push(palletJackVehFleetNumber);

		return fleetNumbers;
	}

	onSave = () => {
		if (!this.isValid())
			return;
		
		this.setState({ isSaving: true, error: null });
		let data = window.$.extend(true, {}, this.state.data);
		data.lastPartProcessStartedOn = this.state.processStartedOn;
		let attachedFiles = [];

		// Signature
		data.dtoSignatureFileIndex = attachedFiles.length;
		attachedFiles.push(data.signatureFile);
		
		// Fault Report Photos
		let FaultReports = data.FaultReports;
		if (FaultReports) {
		// Filter just the ones that don't have an ID, so they are new ones
			FaultReports = FaultReports.filter(f => !f.id);
			for (let i = 0; i < FaultReports.length; i++) {
				let FaultReportPhotos = FaultReports[i].FaultReportPhotos;
				for (let j = 0; j < FaultReportPhotos.length; j++) {
					let faultPhotoObject = FaultReportPhotos[j];
					if (faultPhotoObject.file) {
						let { file } = faultPhotoObject;
						faultPhotoObject.dtoFileIndex = attachedFiles.length;
						attachedFiles.push(file);
						delete faultPhotoObject.dataURL;
					}
				}
			}
		}
		
		// Vehicle Damage Photos
		let VehicleDamages = [];
		let damagePhotosObject = data.VehicleDamages;
		if (damagePhotosObject) {
			for (let fleetNumber in damagePhotosObject) {
				let damagePhotos = damagePhotosObject[fleetNumber];
				for (let i in damagePhotos) {
					let { file } = damagePhotos[i];
					VehicleDamages.push({
						fleetNumber,
						dtoFileIndex: attachedFiles.length
					});
					attachedFiles.push(file);
					delete damagePhotos[i].dataURL;
				}
			}
		}

		const dataToSend = {
			PreStartCheck: {
				...omit(data, ['VehicleDamages'])
			},
			VehicleDamages,
			attachedFiles
		};
			
		let preStartId = data.id;
		PreStartApi.saveLastPartPreStart(preStartId, dataToSend)
			.then(() => {
				return this.setState({ isSaved: true, processStartedOn: undefined });
			})
			.catch(error => {
				console.error(error);
				return this.setState({ isSaving: false, error });
			});
	}
	
	render() {
		const s = this.state;
		const fleetNumbers = this.getFleetNumbers();

		let steps = [
			{
				title: 'Fault Reports',
				content: <FaultReports 
					fleetNumbers={fleetNumbers}
					data={s.data}
					onChange={this.handleChange} 
				/>
			},
			{
				title: 'Vehicle Damages',
				content: <VehicleDamages 
					fleetNumbers={fleetNumbers}
					data={s.data}
					onChange={this.handleChange} 
				/>
			},
			{
				title: 'Depot Return Information',
				content: <DepotReturnInformation 
					mainVehFleetNumber={s.data.mainVehFleetNumber}
					trailer1VehFleetNumber={s.data.trailer1VehFleetNumber}
					trailer2VehFleetNumber={s.data.trailer2VehFleetNumber}
					data={s.data}
					onChange={this.handleChange} 
				/>
			},
			{
				title: 'Signature',
				content: (
					<Form 
						validations={{ signature: 'required' }} 
						messages={{ signature: { required: 'You must provide your signature' }}}
					>
						<Signature onChange={(signatureFile) => { this.handleChange({ signatureFile }); }} />
						{
							!s.data.signatureFile && (
								<div className="text-center">
									<FormControl 
										id="signature" 
										type="hidden" 
										value={s.data.signatureFile ? 'ok' : ''} 
									/>
								</div>
							)
						}
						<br />
						<Button bsStyle="success" bsSize="lg" block onClick={this.onSave}>Submit</Button>
					</Form>
				)
			}
		];
		return (
			<div>
				<Callout color="warning"><b>LAST PART</b></Callout>
				{
					s.isSaving ? (
						<Fragment>
							<DataStatusMessage status={s.isSaved ? 'success' : 'saving'} />
							{ 
								s.isSaved && (
									<FormGroup>
										<Button
											block
											bsSize="large"
											bsStyle="success"
											onClick={() => window.location.reload(true)}
										>
											<Icon name="check" /> Do another Pre-Start Check
										</Button>
									</FormGroup>
								)
							}
						</Fragment>
					) : (
						<div>
							{
								s.showLastPreStartAlert && (
									<AlertModal title="Warning" onClose={() => this.setState({ showLastPreStartAlert: false })}>
										<div className="text-center">
											It seems that you missed the last part of your previous pre-start check. <br/><br/>
											Please, submit the last part now before doing a new one.
										</div>
									</AlertModal>
								)
							}
							{
								s.error && (
									<ErrorBox error={s.error} retryFunc={this.onSave}/>
								)
							}
							<div className="wizard-inline">
								<Row>
									<Col xs={12}>
										<ul className="wizard-steps">
											{
												steps.map((step, index) => {
													return (
														<li key={index} className={this.renderStepClass(index)} title={'Step ' + (index + 1)}>
															<a>{ index + 1 }</a>
														</li>
													);
												})
											}
										</ul>
									</Col>
								</Row>
								{
									steps.map((step, index) => {
										if (index > s.currentStep)
											return null;
										return (
											<div key={index} className={('wizard-content ' + this.renderStepClass(index))}>
												<ContentBox title={step.title} color="primary" windowMaxHeight={true}>
													<Col xs={12}>
														{ step.content }
													</Col>
												</ContentBox>
												<ul className="wizard-buttons">
													{ 
														index > 0 && (
															<li>
																<Button bsStyle="warning" onClick={this.onPreviousStep}>
																	<Icon name="arrow-left" /> Previous
																</Button>
															</li>
														) 
													}
													{ 
														index < steps.length - 1 && (
															<li>
																<Button bsStyle="success" onClick={this.onNextStep}>
																	Next <Icon name="arrow-right" />
																</Button>
															</li>
														) 
													}
												</ul>
											</div>
										);
									})
								}
							</div>
						</div>
					)
				}
			</div>
					
		);
	}
}

PreStartLastCheck.propTypes = propTypes;

export default PreStartLastCheck;