import React, { Fragment } from 'react';
import { Button, PageHeader, Row } from 'react-bootstrap';
import moment from 'moment';

import { MainContent } from '../../../common/layout';
import { Callout, ContentBox, ErrorBox, FieldGroup, Loader } from '../../../common/uiElements';

import { IAccessPoint } from '../../../../app/models/Place';
import { IRunCustomerOrder } from '../../../../app/models/Run/Run';
import { ITaskDetail } from '../../../../app/models/Task';

import MeApi from '../../../../app/api/MeApi';
import MudmapsForDriversModal from './MudmapsForDriversModal';
import FontAwesome from 'react-fontawesome';

interface IState {
	selectedAccessPoint: number | undefined;

	isLoadingTasks: boolean;
	errorLoadingTasks: any;

	task: ITaskDetail[];
}


class MudmapsForDrivers extends React.Component<{}, IState> {
	state = {
		selectedAccessPoint: undefined,

		isLoadingTasks: false,
		errorLoadingTasks: undefined,

		task: [],
	}

	componentDidMount() {
			this.fetchRunOrders();
	}

	fetchRunOrders = () => {
		this.setState({ isLoadingTasks: true, errorLoadingTasks: undefined });

		const startTime = moment().add(-2, 'hours').format('YYYY-MM-DD HH:mm');
		const finishTime = moment().add(2, 'hours').format('YYYY-MM-DD HH:mm');

		MeApi.getTasksInProgress(startTime, finishTime)
			.then(task => {
				let pendingRunTasks = task.filter(t => t.TaskType?.isRunRequired);
				let allTasks = pendingRunTasks.flatMap(task => task).filter(order => order !== undefined);

				this.setState({ task: allTasks });
			})
			.catch(errorLoadingRunOrders => this.setState({ errorLoadingTasks: errorLoadingRunOrders }))
			.finally(() => {
				this.setState({ isLoadingTasks: false })
			});
	}

	handleSelectMudmap = (accessPointId: number) => {
		this.setState({ selectedAccessPoint: accessPointId });
	}

	handleOnClose = () => {
		this.setState({ selectedAccessPoint: undefined });
	}

	renderBodyContent = () => {
		const { state } = this;

		if (state.isLoadingTasks)
			return <Loader text="Grabbing run details..." />;
		
		if (state.errorLoadingTasks)
			return <ErrorBox error={state.errorLoadingTasks} retryFunc={this.fetchRunOrders} />;

		return (
			<Fragment>
				{/* Iterate over every task in the users ActiveTasks */}
				{ state.task.map((t: ITaskDetail, index) => (
					<Fragment key={t.id}>
						<PageHeader>{t.runNumberCombined}</PageHeader>

						{/* Iterate over every Run Customer found for a task */}
						{ t.RunCustomerOrders && t.RunCustomerOrders.map((order: IRunCustomerOrder, index) => (
								<ContentBox style={{ fontWeight: 'bold' }} title={`${index+1} - ${order.placeName}`} key={order.id}>
									<FieldGroup sm={12}>
										<Row>
												{/* 
													TODO: Add a `Reveal` button for door code alarm which prompts user
													their bundy clock code to view the door code alarm.
												*/}
												
												{/* If no Access Points are found, display a callout */}
												{
													order.Place && order.Place.AccessPoints.length === 0 && (
														<Callout color="warning">
															<b>No Mudmap available</b>
														</Callout>
													)
												}

												{/* Iterate over all access points to check for individual mudmaps */}
												{ order.Place && order.Place.AccessPoints.map((accessPoint: IAccessPoint) => (
														<Fragment key={accessPoint.id}>
															<Button 
																onClick={() => this.handleSelectMudmap(accessPoint.id)}
																block
																bsStyle="success"
															>
																<FontAwesome name='search'/> Open Mudmap
															</Button>
														</Fragment>
													))
												}
										</Row>
									</FieldGroup>
								</ContentBox>
							))
						}
					</Fragment>
				))}
			</Fragment>
		)
	}

	render () {
		const { state } = this;

		return (
			<MainContent title="Mudmaps">
				{
					(state.task.length === 0 && !state.isLoadingTasks) && (
						<Callout color="warning">
							<b>No pending runs for today.</b> 
						</Callout>
					)
				}
				{
					state.selectedAccessPoint && (
						<MudmapsForDriversModal 
							selectedAccessPoint={state.selectedAccessPoint}
							onClose={this.handleOnClose}
						/>
					)
				}
				{ this.renderBodyContent() }
			</MainContent>
		)
	}
}

export default MudmapsForDrivers;