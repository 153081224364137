import React, { useEffect, useState } from 'react';
import TrackingApi from '../../../../app/api/TrackingApi';
import { Callout, ErrorBox, Loader } from '../../../common/uiElements';
import { PlacesList } from '../../../common/lists';
import PalletJackBoxList from './PalletJackBoxList';
import ModalPalletJackDetails from './ModalPalletJackDetails';
import { groupBy } from 'lodash';
import { IPalletJackLiveMonitoring } from '../../../../app/models/Panel';
import './style.css';

const PalletJackMonitoring: React.FC = () => {
	const [depotId, setDepotId] = useState<string>("");
	const [selectedPalletFleetNumber, setSelectedPalletFleetNumber] = useState<string>("");
	const [palletJacksList, setPalletJacksList] = useState<IPalletJackLiveMonitoring[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [errorLoading, setErrorLoading] = useState<Error | undefined>(undefined);

	const handleChangeDepot = (e: any) => {
		setDepotId(e.target.value);
	};
	
	const handleSelectPalletJack = (selectedPalletFleetNumber: string) => {
		setSelectedPalletFleetNumber(selectedPalletFleetNumber);
	};
	
	const handleCloseModal = () => {
		setSelectedPalletFleetNumber("");
	};

	const fetchData = (loadInBackground?: boolean) => {
		setIsLoading(!loadInBackground);

		TrackingApi.getPalletJackBatteryInfoLive()
			.then(setPalletJacksList)
			.catch(setErrorLoading)
			.then(() => setIsLoading(false));
	}

	useEffect(() => {
		fetchData();
		const intervalTimer = setInterval(() => fetchData(true), 60000);
		return () => clearInterval(intervalTimer);
	}, []);

	const renderList = (palletJacksList: IPalletJackLiveMonitoring[]) => {
		return (
			<PalletJackBoxList 
				palletJacksList={palletJacksList}
				selectedDepotId={depotId}
				onClickBox={handleSelectPalletJack}
			/>
		);
	}

	const palletJacksListSelectedDepot = palletJacksList.filter(p => p.depotId?.toString() === depotId);
	const palletsOthersDepots = palletJacksList.filter(p => p.depotId?.toString() !== depotId);
	const groupedPalletsOthersDepots = groupBy(palletsOthersDepots, 'depotName');

	const renderContent = () => {
		if (isLoading) return <Loader text="Fetching Pallet Jacks Battery Data..."/>;
		if (errorLoading) return <ErrorBox error={errorLoading} retryFunc={fetchData} />;
		if (!depotId) return <Callout color="info" title="Select a depot to see the dashboard" />;

		return (
			<>
				<PalletJackBoxList 
					palletJacksList={palletJacksListSelectedDepot}
					selectedDepotId={depotId}
					onClickBox={handleSelectPalletJack}
				/>
				{
					Object.keys(groupedPalletsOthersDepots).map((depotName, index) => (
						<div key={index}>
							<h4><b>{ depotName.toUpperCase() }</b></h4>
							{ renderList(groupedPalletsOthersDepots[depotName]) }
						</div>
					))
				}
			</>
		)
	}

	return (
		<section id="pallet-jack-live-monitoring-container" className="content panel-live-monitoring">
			<div className="title">
				<span>Pallet Jack - Live Monitoring</span>
				<PlacesList id="depotId" type="palletJackLiveMonitoring" onChange={handleChangeDepot} />
			</div>
			{ renderContent() }
			{
				selectedPalletFleetNumber && (
					<ModalPalletJackDetails 
						{ ...palletJacksList.find(p => p.fleetNumber === selectedPalletFleetNumber) }
						onClose={handleCloseModal}
					/>
				)
			}
		</section>
	);
}

export default PalletJackMonitoring;