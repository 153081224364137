// This is for a new section in the left menu, mainly for external links 
// import groups from '../app/schemas/PermissionGroups';

// export default  [
// 	{ 
// 		title: 'Department', 
// 		icon: 'pie-chart',
// 		external: true,
// 		urlPath: '/',
// 		authorize: [groups.ADMIN],
// 		nested: [
// 			{
// 				title: 'Delivery Summaries', 
// 				icon: 'truck',
// 				external: true,
// 				urlPath: 'https://micway.au/bi-delivery-summaries',
// 			}, 
// 		]
// 	},
// ]

import groups from '../app/schemas/PermissionGroups';
import WorkerPermission from '../app/enums/WorkerPermission';
import EmbedPowerBI from '../components/features/powerBI/EmbedPowerBI';

const PowerBIRoutes = [
	{
		path: 'deliverySummaries',
		menuItem: { title: 'Delivery Summaries', icon: 'truck' },
		reportId: 'add4d56a-8837-4b9d-bfed-f207e5b4362d',
		allowedPermissionId: WorkerPermission.POWER_BI_DELIVERY_SUMMARIES
	},
	// {
	// 	path: 'palletSummary',
	// 	menuItem: { title: 'Pallet Summaries', icon: 'archive' },
	// 	reportId: '0cea1c84-4f28-46ca-b3cb-ea19d622ac51',
	// 	allowedPermissionId: WorkerPermission.POWER_BI_PALLET_SUMMARIES
	// }
	{
		path: 'palletsAndLoads',
		menuItem: { title: 'Pallets & Loads', icon: 'archive' },
		reportId: 'f4402dd0-37d1-4f14-9d6c-9ad364d0a6c7',
		allowedPermissionId: WorkerPermission.POWER_BI_PALLET_SUMMARIES
	},
	{
		path: 'loaderActivity',
		menuItem: { title: 'Loader Activity', icon: 'user' },
		reportId: '93f576b2-4293-4b5b-881c-f641a9c71b9d',
		allowedPermissionId: WorkerPermission.POWER_BI_PALLET_SUMMARIES
	}
]

export default { 
	path: 'power-bi',
	menuItem: { title: 'Power BI', icon: 'pie-chart' },
	authorize: [groups.ADMIN, WorkerPermission.POWER_BI_FULL],
	childRoutes: PowerBIRoutes.map(route => ({
		...route,
		component: () => EmbedPowerBI({ reportId: route.reportId })
	}))
};