import React from 'react';
import { Callout, Loader } from '../../../../common/uiElements';
import RunsLiveStatusItem from './RunsLiveStatusItem';
import {  RunLiveStatusListItem } from '../../../../../app/models/Run/RunLiveStatus';

interface IProps {
	runsList: RunLiveStatusListItem[];
	isLoading?: boolean;
	onSelect?: (taskId: number) => void;
}

const RunsLiveStatusList: React.FC<IProps> = props => {

	const renderRunsList = () => {
		if (props.isLoading) 
			return <Loader marginTop={15} text="Loading Runs..."/>;

		if (props.runsList.length === 0)
			return (
				<Callout italicTitle title="NO RUNS TO SHOW" color="info">
					No runs found with the selected filters. Try changing the filters or refresh the list.
				</Callout>
			)

		return props.runsList.map(run => (
			<RunsLiveStatusItem 
				key={run.id}
				data={run}
				onClick={() => props.onSelect && props.onSelect(run.Task.id)}
			/>
		))
	}

	return (
		<div id="runs-live-status-panel">
			<div className="flex flex-column panel-row-container panel-list-header">
				<div className="flex-1 flex panel-row">
					<span className="flex-2">RUN</span>
					<span className="flex-2">DEPOT</span>
					<span className="flex-1">START</span>
					<span className="flex-1">BUDG.</span>
					<span className="flex-1">DUR.</span>
					<span className="flex-3">FLEET</span>
					<span className="flex-3">DRIVER</span>
					<span className="flex-2">DELIVERED</span>
					<span className="flex-3">STATUS</span>
				</div>
			</div>
			{ renderRunsList()}
		</div>
	);
}

export default RunsLiveStatusList;
