import React from 'react';
import { Row, FormControl } from 'react-bootstrap';
import { CheckboxList } from '../../../../common/inputs';
import { ContentBox, FieldGroup } from '../../../../common/uiElements';
import { RunLiveStatusListFilter } from '../../../../../app/models/Run/RunLiveStatus';
import { PlacesListNew } from '../../../../common/lists';
import { IReactSelectProps } from '../../../../../app/models/Application';

interface IProps extends RunLiveStatusListFilter {
	onChangeWorker: (worker: string) => void;
	onChangeFleetNumber: (fleetNumber: string) => void;
	onChangeRunNumber: (runNumber: string) => void;
	onChangeStore: (store: string) => void;
	onChangeDepot: (depotId: string) => void;
	onChangeStatus: (status: string[]) => void;
	onChangeRunType: (runTypes: string[]) => void;
}

const RunsLiveStatusPanelFilter: React.FC<IProps> = props => {	
	const handleChangeInput = (e: React.ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value } = e.target;

		switch (id) {
			case 'runNumber':
				return props.onChangeRunNumber(value);
			case 'workerName':
				return props.onChangeWorker(value);
			case 'fleetNumber':
				return props.onChangeFleetNumber(value);
			case 'store':
				return props.onChangeStore(value);
			default:
				break;
		}
	}

	const handleChangeDepot = (depot?: IReactSelectProps) => {
		props.onChangeDepot(depot?.value || '');
	};

	const handleChangeStatus = (status: string[]) => {
		props.onChangeStatus(status);
	}

	const handleChangeRunType = (runTypes: string[]) => {
		props.onChangeRunType(runTypes);
	}

	return (
		<ContentBox>
			<Row>
				{/* RUN NUMBER ----------------------------------------- */}
				<FieldGroup label="Run Number" sm={6} md={4} lg={3}>
					<FormControl
						id="runNumber"
						value={props.runNumber}
						onChange={handleChangeInput}
						placeholder="Filter by Run Number..."
					/>
				</FieldGroup>
				{/* ----------------------------------------------------- */}
				
				{/* FLEET NUMBER --------------------------------------- */}
				<FieldGroup label="Fleet Number" sm={6} md={4} lg={3}>
					<FormControl
						id="fleetNumber"
						value={props.fleetNumber}
						onChange={handleChangeInput}
						placeholder="Filter by Fleet Number..."
					/>
				</FieldGroup>
				{/* -----------------------------------------------------  */}

				{/* WORKER ---------------------------------------------- */}
				<FieldGroup label="Driver / Offsider / Trainee" sm={6} md={4} lg={3}>
					<FormControl
						id="workerName"
						value={props.workerName}
						onChange={handleChangeInput}
						placeholder="Filter by First or Last Name..."
					/>
				</FieldGroup>
				{/* ----------------------------------------------------- */}

				{/* STORE ----------------------------------------------- */}
				<FieldGroup label="Store" sm={6} md={4} lg={3}>
					<FormControl
						id="store"
						value={props.store}
						onChange={handleChangeInput}
						placeholder='Filter by Store Name or Number...'
					/>
				</FieldGroup>
				{/* ----------------------------------------------------- */}

				{/* DEPOT ----------------------------------------------- */}
				<FieldGroup label="Starting Depot" sm={6} md={4} lg={3}>
					<PlacesListNew
						type="rosterStartingDepot"
						placeholder="All Depots"
						value={props.depotId || ''}
						onChange={handleChangeDepot}
					/>
				</FieldGroup>
				{/* ----------------------------------------------------- */}

				{/* RUN TYPE -------------------------------------------- */}
				<FieldGroup label="Run Type" sm={12} md={6} lg={3}>
					<CheckboxList
						value={props.runTypes}
						items={[
							{ text: 'Woolworths', value: 'WW' },
							{ text: 'Food Service', value: 'FS' },
							{ text: 'Shuttle', value: 'SHUTTLE' },
						]}
						onChange={handleChangeRunType}
					/>
				</FieldGroup>
				{/* ----------------------------------------------------- */}

				{/* STATUS ---------------------------------------------- */}
				<FieldGroup label="Status" sm={12} md={6} lg={6}>
					<CheckboxList
						value={props.status}
						items={[
							{ text: 'Late Start', value: 'LATE_START' },
							{ text: 'Not Started', value: 'NOT_STARTED' },
							{ text: 'In Progress', value: 'IN_PROGRESS' },
							{ text: 'Coming Back', value: 'COMING_BACK' },
							{ text: 'Completed', value: 'COMPLETED' },
						]}
						onChange={handleChangeStatus}
					/>
				</FieldGroup>
				{/* ----------------------------------------------------- */}

			</Row>
		</ContentBox>
	);
}

export default RunsLiveStatusPanelFilter;