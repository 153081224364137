import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { RunLiveStatusListItem } from '../../../../../app/models/Run/RunLiveStatus';
import moment from 'moment';

interface IProps {
	data: RunLiveStatusListItem;
	onClick: (taskId: number) => void;
}

const RunsLiveStatusItem: React.FC<IProps> = props => {
	const { 
		arrivalTime, arrivalETA, runNumberCombined,
		Task, Driver, /*Offsider, Trainee,*/ MainVehicle, Trailer1, Trailer2,
		PreStart, 
		actualFinishTime, minutesToStart, isRunStarted,
		isRunCompleted, isRunStartLate, isComingBack, percentageStoresDelivered,
		totalStores, totalStoresCompleted, currentStore, nextStore, isOverBudget, minutesToBeBack
	} = props.data;

	// Run duration in seconds from start to finish
	const [runDurationInMinutes, setRunDurationInMinutes] = useState(props.data.runDurationInMinutes);

	// Pre-Start Data
	const preStartFirstPartStartedOn = PreStart?.processStartedOn;
	const preStartFirstPartCompletedOn = PreStart?.inspectedOn;
	const preStartLastPartStartedOn = PreStart?.lastPartProcessStartedOn;
	const preStartLastPartCompletedOn = PreStart?.lastReportedOn;

	// If isStartTimeAdjustNeeded == true, it means the start time is not correct, so we don't show it
	const $startTime = useMemo(() => Task.isStartTimeAdjustNeeded ? undefined : moment(Task.startTimeLocal), [Task.startTimeLocal, Task.isStartTimeAdjustNeeded]);
	const fleetCombination = [MainVehicle, Trailer1, Trailer2].filter(vehicle => vehicle).map(vehicle => vehicle?.fleetNumber).join(', ');

	useEffect(() => {	
		// If run hasn't started or is completed, don't start the interval
		if (!isRunStarted || isRunCompleted) 
			return;

		const intervalForRunDuration = setInterval(() => {
			// Clear interval if run is completed
			if (isRunCompleted) 
				return clearInterval(intervalForRunDuration);

			setRunDurationInMinutes(prev => prev + 60 * 5);
		}, 60 * 5 * 1000) as unknown as number; // every 5 minutes (as unknown as number to avoid TS error)
	
		return () => clearInterval(intervalForRunDuration);
	}, [isRunStarted, isRunCompleted]); 

	if (Task.id === 359818) {
		console.log(props.data)	
		console.log(actualFinishTime)
	}

	const status = useMemo(() => {
		if (isRunStartLate)
			return { style: 'danger', bgColor: 'bg-red' };
		if (!isRunStarted)
			return { style: 'muted', bgColor: 'bg-gray' };
		if (isComingBack || isRunCompleted || arrivalTime)
			return { style: 'success', bgColor: 'bg-green' };
		if (isOverBudget)
			return { style: 'warning', bgColor: 'bg-orange' };
		
		return { style: undefined, bgColor: 'bg-blue' };
	}, [isRunStarted, isRunCompleted, isOverBudget, isRunStartLate, isComingBack, arrivalTime]);

	const currentStatusText = useMemo(() => {
		if (isRunCompleted) return `Completed`;
		if (!isRunStarted) {
			if (Task.isStartTimeAdjustNeeded) return 'Start Time not set';
			if (minutesToStart < 0) 
				return `Late by ${Math.abs(minutesToStart)} min`;
			
			if (minutesToStart < 60)
				return `Starting in ${minutesToStart} min`;	
			else
			return `Starting in ${(minutesToStart / 60).toFixed(1)} h`;	
		}
		if (preStartFirstPartStartedOn && !preStartFirstPartCompletedOn) return 'Doing Pre-Start';
		if (preStartLastPartStartedOn && !preStartLastPartCompletedOn) return 'Closing Pre-Start';
		if (isComingBack && !arrivalTime) {
			if (arrivalETA) return `~${minutesToBeBack} min away`;
			else return `Coming Back (No ETA)`;
		}
		if (currentStore) return `At ${currentStore.Store.name}`;
		if (nextStore) return `Driving to ${nextStore.Store.name}`;
		if (arrivalTime) {
			const minutesSinceArrival = moment.utc().diff(moment.utc(arrivalTime), 'minutes');
			return `Arrived back ${minutesSinceArrival} min ago`;
		}
	}, [
		isRunCompleted, isRunStarted, preStartFirstPartStartedOn, preStartFirstPartCompletedOn, preStartLastPartStartedOn, 
		preStartLastPartCompletedOn, isComingBack, currentStore, nextStore, minutesToStart, arrivalETA, minutesToBeBack,
		Task.isStartTimeAdjustNeeded, arrivalTime
	]);
	return (
		<div>
			<div 
				className={classNames(
					"panel-row-container", "cursor-pointer", status.bgColor, 
					{ "blink": (!isRunCompleted && ((minutesToBeBack && minutesToBeBack < 5) || isRunStartLate)) }
				)} 
				onClick={() => props.onClick(Task.id)}
			>
				<div className="flex-1 flex flex-row panel-row">
					{/* RUN NUMBER */}
					<span className="flex-2">{ runNumberCombined }</span>

					{/* DEPARTURE TIME */}
					<span className="flex-2">{ Task.startingDepotName }</span>

					{/* START TIME */}
					<span className="flex-1">{ $startTime ? $startTime.format("HH:mm") : "-" }</span>

					{/* BUDGETED TIME */}
					<span className="flex-1">{ Task.budgetedTimeInMin ? `${(Task.budgetedTimeInMin / 60).toFixed(1)} h` : '-' } </span>
					
					{/* DURATION */}
					<span className={classNames("flex-1", { "blink": isOverBudget })}>
						{ !isRunStarted ? '-' : `${(runDurationInMinutes / 60).toFixed(1)} h` }
					</span>

					{/* FLEET COMBINATION */}
					<span className="flex-3">{ fleetCombination || <i>No Vehicle</i> }</span>

					{/* DRIVER */}
					<span className="flex-3">{ Driver?.shortName || <i>No Driver</i> }</span>

					{/* STORES DELIVERED */}
					<span className="flex-2">{ totalStoresCompleted } / { totalStores }</span>

					{/* CURRENT STATUS */}
					<span className="flex-3">
						{ currentStatusText }
					</span>
					<ProgressBar 
						active={!actualFinishTime}
						now={percentageStoresDelivered} 
						bsStyle={status.style}
					/>
				</div>
			</div>
		</div>
	)
}

export default RunsLiveStatusItem;