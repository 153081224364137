import React, { ChangeEvent, Fragment, useEffect } from 'react';
import Select from 'react-select';
import { FormControl, FormControlProps } from 'react-bootstrap';
import classNames from 'classnames';
import { AustralianState, ColorsBootstrap } from '../../../app/models/Application';
import { IVehicleDetailsForTask } from '../../../app/models/Vehicle';
import { getVehicleServiceOverlappingTask } from '../../features/roster/tasks/tasksHelper';
import { observer } from 'mobx-react-lite';
import listsStore from '../../../stores/mobx/database/listsStore';

interface IProps extends Omit<FormControlProps, 'onChange'> {
	showInactive?: boolean;
	vehTypeFilter?: number[];
	stateFilter?: AustralianState[];
	color?: ColorsBootstrap;
	normalSelectInput?: boolean;
	checkAvailabilityDate?: string;
	excludeFleetNumbers?: string[];
	includeFleetNumbers?: string[];
	isLoading?: boolean;
	onChange?: (vehicle?: IVehicleDetailsForTask, event?: ChangeEvent<FormControl & HTMLInputElement>) => void;
}

const VehicleSelectForTask = observer((props: IProps) => {
	const { data, isFetching, error } = listsStore.vehiclesForTask;

	useEffect(() => {
		listsStore.fetchVehiclesForTasks();
	}, []);

	const handleChange = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const vehicle = data?.find((p: IVehicleDetailsForTask) => p.fleetNumber === e.target.value)
		props.onChange && props.onChange(vehicle, e);
	}

	const handleChangeSearchInput = (newValue: any) => {
		const fleetNumber = newValue?.value;
		const Vehicle = data?.find((p: IVehicleDetailsForTask) => p.fleetNumber === fleetNumber);
		props.onChange && props.onChange(Vehicle);
	}

	const renderSearchInputContent = () => {
		const { checkAvailabilityDate, includeFleetNumbers, excludeFleetNumbers } = props;

		let filteredVehiclesList = data || [];

		if (props.vehTypeFilter?.length)
			filteredVehiclesList = filteredVehiclesList.filter(vehicle => props.vehTypeFilter!.includes(vehicle.typeId));

		if (includeFleetNumbers && includeFleetNumbers.length > 0)
			filteredVehiclesList = filteredVehiclesList.filter(vehicle => includeFleetNumbers.includes(vehicle.fleetNumber) === true);

		if (excludeFleetNumbers && excludeFleetNumbers.length > 0)
			filteredVehiclesList = filteredVehiclesList.filter(vehicle => excludeFleetNumbers.includes(vehicle.fleetNumber) === false);

		return filteredVehiclesList.map((data: IVehicleDetailsForTask) => {
			const isBookedForService = checkAvailabilityDate && data.MaintenanceServices && getVehicleServiceOverlappingTask(checkAvailabilityDate, 10, data.MaintenanceServices);

			return {
				value: data.fleetNumber,
				label: data.fleetNumber + (data.regoNumber ? ` - ${data.regoNumber}` : '') + (isBookedForService ? ' (In Service)' : ''),
				className: isBookedForService ? 'text-danger' : ''
			}
		})
	}

	const renderSelectContent = () => {
		const { excludeFleetNumbers, includeFleetNumbers } = props;

		let filteredVehiclesList = data || [];
		
		let firstOptionText = '--- SELECT ---';

		if (isFetching)
			firstOptionText = 'Loading vehicles. Please wait...';
		
		if (error)
			firstOptionText =  'It was not possible to retrieve the vehicles.';

		if (props.vehTypeFilter?.length)
			filteredVehiclesList = filteredVehiclesList.filter(vehicle => props.vehTypeFilter!.includes(vehicle.typeId));

		if (includeFleetNumbers && includeFleetNumbers.length > 0)
			filteredVehiclesList = filteredVehiclesList.filter(vehicle => includeFleetNumbers.includes(vehicle.fleetNumber));

		if (excludeFleetNumbers && excludeFleetNumbers.length > 0)
			filteredVehiclesList = filteredVehiclesList.filter(vehicle => excludeFleetNumbers.includes(vehicle.fleetNumber) === false);

		return [(<option value="" key={-1}>{ firstOptionText }</option>),
		filteredVehiclesList.map((vehicle: IVehicleDetailsForTask) => (
			<option key={vehicle.fleetNumber} value={vehicle.fleetNumber}>
				{ vehicle.fleetNumber } {  (vehicle.regoNumber ? ` - ${vehicle.regoNumber}` : '') }
			</option>
		))
	]
	};

	if (props.normalSelectInput)
		return (
			<FormControl
				componentClass="select"
				id={props.id}
				className={classNames(
					props.className, 
					{ [`form-control-${props.color}`]: props.color } 
				)}
				value={props.value}
				bsSize={props.bsSize}
				disabled={props.disabled}
				onChange={handleChange as any}
			>
			{ renderSelectContent() }
			</FormControl>
		)
		
	return (
		<Fragment>
			<Select 
				noResultsText={isFetching ? 'Loading...' : 'No vehicles found'}
				className={props.className}
				disabled={props.disabled}
				name={props.id}
				value={props.value as any}
				options={renderSearchInputContent()}
				onChange={handleChangeSearchInput as any}
				isLoading={isFetching || props.isLoading}
				placeholder={isFetching ? 'Loading...' : props.placeholder}
			/>
			<input id={props.id} type="hidden" value={props.value} />
		</Fragment>
	);
});

export { VehicleSelectForTask };