import moment from "moment";

// #region RunLiveStatusItem
export interface IRunLiveStatusListItem_GET {
	id: number;
	runNumberCombined: string;
	departureTime: Date | null;
	arrivalTime: Date | null;
	arrivalETA: Date | null;

	// Task
	Task: ITaskForRunLiveStatus;
	PreStart: IPreStartForRunLiveStatus | null;

	// Workers
	Driver: IWorkerForRunLiveStatus | null;
	Offsider: IWorkerForRunLiveStatus | null;
	Trainee: IWorkerForRunLiveStatus | null;

	// Vehicles
	MainVehicle: IVehicleForRunLiveStatus | null;
	Trailer1: IVehicleForRunLiveStatus | null;
	Trailer2: IVehicleForRunLiveStatus | null;

	// Stores
	RunCustomerOrders: IRunCustomerOrderForRunLiveStatus[];
}

export class RunLiveStatusListItem implements IRunLiveStatusListItem_GET {
	id: number;
	runNumberCombined: string;
	departureTime: Date | null;
	arrivalTime: Date | null;
	arrivalETA: Date | null;

	// Task
	Task: ITaskForRunLiveStatus;
	PreStart: IPreStartForRunLiveStatus | null;

	// Workers
	Driver: IWorkerForRunLiveStatus | null;
	Offsider: IWorkerForRunLiveStatus | null;
	Trainee: IWorkerForRunLiveStatus | null;

	// Vehicles
	MainVehicle: IVehicleForRunLiveStatus | null;
	Trailer1: IVehicleForRunLiveStatus | null;
	Trailer2: IVehicleForRunLiveStatus | null;

	// Stores
	RunCustomerOrders: IRunCustomerOrderForRunLiveStatus[];

	// Calculated (UI Only)
	actualStartTime?: Date;
	actualFinishTime?: Date;
	minutesToStart: number;
	minutesToBeBack: number;
	isRunStarted: boolean;
	isRunCompleted: boolean;
	isRunStartLate: boolean;
	isComingBack: boolean;
	isOverBudget: boolean;
	totalStores: number;
	totalStoresCompleted: number;
	currentStore?: IRunCustomerOrderForRunLiveStatus;
	nextStore?: IRunCustomerOrderForRunLiveStatus;
	percentageStoresDelivered: number;
	runDurationInMinutes: number;

	constructor(runData: IRunLiveStatusListItem_GET) {
		this.id = runData.id;
		this.runNumberCombined = runData.runNumberCombined;
		this.departureTime = runData.departureTime;
		this.arrivalTime = runData.arrivalTime;
		this.arrivalETA = runData.arrivalETA;
		this.Task = runData.Task;
		this.PreStart = runData.PreStart;
		this.Driver = runData.Driver;
		this.Offsider = runData.Offsider;
		this.Trainee = runData.Trainee;
		this.MainVehicle = runData.MainVehicle;
		this.Trailer1 = runData.Trailer1;
		this.Trailer2 = runData.Trailer2;
		this.RunCustomerOrders = runData.RunCustomerOrders;

		const { Task, PreStart, RunCustomerOrders } = this;

		// If PreStart is not started, we use departure time (tracking data), otherwise we use the pre-start first part start time
		this.actualStartTime = PreStart?.processStartedOn || this.departureTime || undefined;

		// If PreStart is not completed, we use arrival time (tracking data), otherwise we use the pre-start last part completion time
		this.actualFinishTime = PreStart?.lastReportedOn || (!PreStart && this.arrivalTime) || undefined;

		// Minutes for the run to start based of task start time
		this.minutesToStart = Task.isStartTimeAdjustNeeded ? 0 : moment(Task.startTimeLocal).diff(moment.utc(), 'minutes');

		// Total minutes for the driver to be back to starting depot (uses maps API)
		this.minutesToBeBack = this.arrivalETA ? moment.utc(this.arrivalETA).diff(moment.utc(), 'minutes') : 0;

		// If there's actual start time it has started
		this.isRunStarted = !!this.actualStartTime;

		// If there's actual finish time it has completed
		this.isRunCompleted = !!this.actualFinishTime;

		// If the run has not started and it's past the start time
		this.isRunStartLate = !this.isRunStarted && this.minutesToStart < 0;

		// IF there's an arrival ETA or if all stores are completed, it's coming back
		// (No arrival ETA but all stores completed means something wrong with API)
		this.isComingBack = !this.arrivalTime && !this.isRunCompleted && (!!this.arrivalETA || RunCustomerOrders.every(rco => rco.storeDepartureTime));

		// Total stores to be delivered
		this.totalStores = RunCustomerOrders.length;

		// Total stores delivered
		this.totalStoresCompleted = RunCustomerOrders.filter(rco => rco.storeDepartureTime).length;

		// Current store being delivered (has arrival time but no departure time)
		this.currentStore = RunCustomerOrders.find(rco => rco.storeArrivalTime && !rco.storeDepartureTime);

		// Next store to be delivered (no arrival time)
		this.nextStore = RunCustomerOrders.find(rco => !rco.storeArrivalTime);

		this.runDurationInMinutes = moment.utc(this.actualFinishTime).diff(moment.utc(this.actualStartTime), 'minutes');
		
		// If the duration is over the budgeted time (plus 2 minutes to avoid warnings when its not necessary when converting it to hours to show)
		this.isOverBudget = (this.runDurationInMinutes - 2) > Task.budgetedTimeInMin;
		
		// Percentage of stores to be delivered vs stores delivered
		this.percentageStoresDelivered = Math.min(100, 
			Math.round((this.totalStoresCompleted / this.totalStores) * 100)
			// Add 10% if it has departure time, or 5% if it has only pre-start
			+ (this.departureTime ? 10 : PreStart?.processStartedOn ? 5 : 0)
			// Add 100% for completion
			+ (this.isRunCompleted ? 100 : 0)
		)
	}
}
// #endregion

// #region ITask
export interface ITaskForRunLiveStatus {
	id: number;
	startTimeLocal: Date;
	budgetedTimeInMin: number;
	startingDepotId: number;
	startingDepotName: string;
	isStartTimeAdjustNeeded: boolean;
}
// #endregion

// #region IWorker
export interface IWorkerForRunLiveStatus {
	azureId: string;
	shortName: string;
	phoneNumber?: string;
	photoUrl?: string;
}
// #endregion

// #region IRunCustomerOrder
export interface IRunCustomerOrderForRunLiveStatus {
	id: number;
	Store: IPlaceForRunLiveStatus;

	storeArrivalTime?: Date | null;
	storeDepartureTime?: Date | null;

	openWindow?: Date;
	closeWindow?: Date;
}
// #endregion

// #region IVehicle
export interface IVehicleForRunLiveStatus {
	fleetNumber: string;
	typeId: number;
	typeName: string;
	regoNumber: string;
	maxPalletCapacity?: number | null;
}
// #endregion

// #region IPlace
export interface IPlaceForRunLiveStatus {
	id: number;
	name: string;
	storeNumber?: string | null;
}
// #endregion

// #region IRunLiveStatusItem
export class RunLiveStatusListFilter {
	status: string[] = [];
	runTypes: string[] = [];
	fleetNumber = '';
	depotId = '';
	workerName = '';
	runNumber = '';
	store = '';
}


// #region IPreStartForRunLiveStatus
export interface IPreStartForRunLiveStatus {
	id: string;
	processStartedOn: Date;
	inspectedOn: Date;
	lastPartProcessStartedOn: Date;
	lastReportedOn?: Date;
}
// #endregion