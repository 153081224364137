import React, { Fragment } from "react";

import { Callout, ErrorBox, Loader } from "../../../common/uiElements";

import GeofenceApi from "../../../../app/api/GeofenceApi";
import { Modal } from 'react-bootstrap';

interface IState {
	isDownloadingMudmaps: boolean;
	errorRetrievingMudmaps: any,
	
	mudmapPath: string;
}

interface IProps {
	selectedAccessPoint: number | undefined;
	onClose: () => void;
}

class MudmapsForDriversModal extends React.Component<IProps, IState> {
	state = {
		isDownloadingMudmaps: false,
		errorRetrievingMudmaps: undefined,
		
		mudmapPath: ''
	}

	componentDidMount() {
		this.fetchMudMaps();
	}

	fetchMudMaps = () => {
		this.setState({ isDownloadingMudmaps: true, errorRetrievingMudmaps: undefined });
		if (!this.props.selectedAccessPoint) return;

		GeofenceApi.getMudMapStream(this.props.selectedAccessPoint)
			.then((data) => {
				const blob = new Blob([data], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob) + "#page=1&view=FitH&toolbar=0";

				this.setState({ mudmapPath: url, isDownloadingMudmaps: false });
			})
			.catch(errorRetrievingMudmaps => this.setState({ errorRetrievingMudmaps: errorRetrievingMudmaps }))
		}

	renderBodyContent = () => {
		const { state } = this;

		if (state.isDownloadingMudmaps) {
			return <Loader text="Loading mudmap..." />;
		}

		if (state.errorRetrievingMudmaps) {
			return <ErrorBox error={state.errorRetrievingMudmaps} retryFunc={this.fetchMudMaps} />;
		}

		if (!this.props.selectedAccessPoint)
			return (
				<Callout color="warning">
					<b>No access point information available.</b> 
				</Callout>
			)

		return (
			<>
				{/* <b><a href={state.mudmapPath} target="_blank" rel="noreferrer">View mudmap in another tab <Icon name="external-link" /></a></b><br/><br/> */}
				<iframe title={state.mudmapPath} src={state.mudmapPath} width='100%' height={window.innerHeight}/>
			</>
		)

	}

	render() {
		const { props } = this;

		return (
			<Fragment>
				<Modal
					show
					id="mudmap-modal"
					bsSize="large"
					onHide={props.onClose}
					onEscapeKeyDown={props.onClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							Access point: { props.selectedAccessPoint }
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						{ this.renderBodyContent() }
					</Modal.Body>

				</Modal>
			</Fragment>
		)
	}
}

export default MudmapsForDriversModal;
