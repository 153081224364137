import React from 'react';
import PropTypes from 'prop-types';

import { Marker } from 'react-google-maps';

const propTypes = {
	markerRef: PropTypes.func,
	labelText: PropTypes.string,
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
	isActive: PropTypes.bool.isRequired,
	markerColour: PropTypes.oneOf(['green', 'blue', 'grey']),
	labelColour: PropTypes.oneOf(['yellow', 'blue', 'white']),
	draggable: PropTypes.bool,
	onDragEnd: PropTypes.func
};

const defaultProps = {
	labelColour: 'black',
	markerColour: 'green'
};

const AccessPointMarker = ({
	markerRef,
	lat,
	lng,
	isActive,
	labelText,
	labelColour,
	markerColour,
	draggable,
	onDragEnd
}) => (
	<Marker
		draggable={draggable}
		ref={markerRef}
		icon={`https://cdn.micway.com.au/tms/img/map_marker_${isActive ? markerColour : 'grey'}.png`}
		label={{
			text: `
				${labelText ? labelText : ''}
				${isActive ? '' : `\n(Deleted)`}
			`, 
			className: 'google-maps-marker-label-new default-marker',
			color: isActive ? labelColour : 'gray',
			opacity: isActive ? 1 : .9
		}}
		position={{ lat: parseFloat(lat), lng: parseFloat(lng) }} 
		zIndex={2}
		onDragEnd={onDragEnd}
	/>
);

AccessPointMarker.propTypes = propTypes;
AccessPointMarker.defaultProps = defaultProps;

export default AccessPointMarker;
